import { getRequest, postRequest } from "./axiosClient";

export const getAllPlans = () => getRequest("plans");

export const charge = (data) => postRequest("plans/charge", data);

export const sendNow = (data) => postRequest("plans/sendNow", data);

export const createPaymentIntent = (data) => postRequest("plans/create-payment-intent", data);

export const refund = (id, withUser = false) => postRequest(`plans/refund/${id}`, { withUser });
