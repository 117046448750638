import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { getStorage } from "../../helpers";

function PublicRoute() {
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  let registered = getStorage('registered');
  let to = location.state?.from || (registered ? 'https://fixphotos.ai/signup_flow' : '/restore');
  if(isAuthenticated && to.toString().includes('https://')) {
    // console.log(to);
    window.location.href = to;
    return;
  };
  // https://fixphotos.ai/signup_flow
  return !isAuthenticated ? <Outlet /> : <Navigate to={to} />
}

export default PublicRoute;