import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Dropdown, Image } from "antd";
import {
  LockOutlined,
  UserOutlined,
  SafetyOutlined,
  IdcardOutlined,
  DownOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { GiShoppingCart } from 'react-icons/gi';

import { logout } from "../../../redux/auth/authSlice";
import { getStorage } from "../../../helpers";

const UserMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const items = [
    {
      label: "Restore Photo",
      key: getStorage('upload') || "/restore",
      icon: <FileImageOutlined className="!text-[16px]" />,
    },
    {
      label: "Purchase",
      key: getStorage('purchase') || "/purchase",
      icon: <GiShoppingCart className="!text-[18px]" />,
    },
    // {
    //   label: "Referrals",
    //   key: "/referrals",
    //   icon: <DollarOutlined />,
    // },
    {
      type: "group",
      label: user.name,
      children: [
        {
          label: "Profile",
          key: "/user/profile",
          icon: <IdcardOutlined className="!text-[16px]" />,
        },
        {
          label: "Log Out",
          key: "/auth/logout",
          icon: <LockOutlined className="!text-[16px]" />,
        },
      ],
    },
    {
      label: "Admin",
      key: "admin",
      type: "group",
      icon: <SafetyOutlined className="!text-[16px]" />,
      children: [
        {
          label: "Users",
          key: "/admin/users",
          icon: <UserOutlined className="!text-[16px]" />,
        },
        // {
        //   label: "Analyze",
        //   key: "/admin/analyze",
        //   icon: <AreaChartOutlined />,
        // },
      ],
    },
  ];

  const handleClick = ({ item, key }) => {
    if (key === "/auth/logout") {
      dispatch(logout());
    } else if (key === "support") {
      window.open("mailto:hello@trafficmagic.ai", "_blank");
    } else {
      navigate(key);
    }
  };

  return (
    <Dropdown
      menu={{
        items: items.filter(
          (item) =>
            item.key !== "admin" || (item.key === "admin" && user.isAdmin)
        ),
        onClick: handleClick,
      }}
      trigger="click"
      placement="bottom"
      arrow
    >
      <Button
        className="flex items-center ml-auto"
        type="primary"
        icon={<UserOutlined />}
        title={user.name}
      >
        <span className="truncate max-w-[100px] text-[15px] !hidden sm:!inline-block">
          {user.name}
        </span>{" "}
        <DownOutlined className="!ml-0 sm:!ml-2" />
      </Button>
    </Dropdown>
  );
};

export default UserMenu;
