import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { UserAddOutlined, UnlockOutlined } from "@ant-design/icons";

import { clearErrors } from "../../redux/auth/authSlice";

const { Header, Content, Footer } = Layout;

const items = [
  {
    label: <span className="drop-shadow-xl text-[15px] mr-5"><UnlockOutlined className="mr-2" /> Login</span>,
    key: "/login",
    // icon: ,
  },
  {
    label: <span className="drop-shadow-xl text-[15px]"> <UserAddOutlined className="mr-2" /> Register</span>,
    key: "/register",
    // icon: ,
  },
];

function GuestLayout({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = ({ item, key }) => {
    dispatch(clearErrors());
    navigate(key + location.search);
  };

  return (
    <Layout>
      {/*<Sider/>*/}
      <Layout>
        <Header className="bg-white bg-cover h-[80px] sticky top-0 z-[100] shadow px-2 pr-5">
          <div className="max-w-5xl mx-auto flex items-center justify-between h-full">
            <div className="demo-logo">
              <Link to="/dashboard" className="hidden sm:inline">
                <img src="/imgs/logo.png" alt="logo" className="h-[64px] p-3" />
              </Link>
              <Link to="/dashboard" className="inline sm:hidden">
                <img src="/imgs/logo-sm.png" alt="logo" className="w-[64px] p-3" />
              </Link>
            </div>
            <div className="w-[200px]">
              <Menu
                className="bg-transparent text-black border-0"
                mode="horizontal"
                items={items}
                defaultSelectedKeys={[location.pathname]}
                onClick={handleClick}
              />
            </div>
          </div>
        </Header>
        <Content
          className="w-screen flex items-center justify-center min-h-[calc(100vh_-_144.09px)]"
          // style={{ minHeight: "calc(100vh - 144.09px)" }}
        >
          {children}
        </Content>
        <Footer className='bg-white text-center'>
          Copyright © 2023 FixPhotos.ai
        </Footer>
      </Layout>
    </Layout>
  );
}

export default GuestLayout;
