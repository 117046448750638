const constants = {
  HOST_URL: process.env.REACT_APP_HOST_URL || "/api/",
  // HOST_URL: process.env.REACT_APP_HOST_URL || 'http://46.175.146.14:5000/api/',
  // HOST_URL: process.env.REACT_APP_HOST_URL || 'http://127.0.0.1:5000/api/',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  multipartHeaders: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  stripePK: "pk_live_51O9RiuIjwMxrBR2FeBjBER32Jzcv9NFI0YvOe4we6UpNkf9FztbuckUBViOxpcmusFvJp9azz2aI5BPewtZNmVTc00yVZPmxPl",
  // stripePK: "pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu",
  SOCKET_URL: "/",
  // SOCKET_URL: 'http://46.175.146.14:5000/',
  // SOCKET_URL: 'http://127.0.0.1:5000/',
  COLORS: ['#f50', '#2db7f5', '#87d068', '#108ee9', '#68904D', '#9db800', '#fdac07'],
};

export default constants;
