import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";

function PaymentCheckRoute() {
  // const location = useLocation();
  const isPayed = useSelector((state) => !!state.auth.user.planId);
  
  return isPayed ? <Outlet /> : <Navigate to="/purchase" />;
}

export default PaymentCheckRoute;
