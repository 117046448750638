import { lazy } from 'react';

const routes = [
  {
    path: "restore",
    component: lazy(() => import("../pages/Home/Home")),
    // isPayed: true,
    exact: true,
  },
  {
    path: "upload",
    component: lazy(() => import("../pages/Home/UploadPhoto")),
    // isPayed: true,
    exact: true,
  },
  {
    path: "restore-now",
    component: lazy(() => import("../pages/Home/Upload")),
    exact: true,
  },
  {
    path: "success",
    component: lazy(() => import("../pages/Home/Success")),
    exact: true,
  },
  {
    path: "successful",
    component: lazy(() => import("../pages/Home/SuccessNew")),
    exact: true,
  },
  {
    path: "photos/:id/result",
    component: lazy(() => import("../pages/Photos/Result")),
    exact: true,
  },
  // {
  //   path: "dashboard",
  //   component: lazy(() => import("../pages/Dashboard/Dashboard")),
  //   exact: true,
  // },
  // {
  //   path: "dashboard/:userId",
  //   component: lazy(() => import("../pages/Dashboard/Dashboard")),
  //   isAdmin: true,
  //   exact: true,
  // },
  {
    path: "purchase",
    component: lazy(() => import("../pages/Billing/Plan")),
    exact: true,
  },
  {
    path: "purchase-now",
    component: lazy(() => import("../pages/Billing/PlanNew")),
    exact: true,
  },
  {
    path: "welcome",
    component: lazy(() => import("../pages/Welcome/Welcome")),
    exact: true,
  },
  {
    path: "last-step",
    component: lazy(() => import("../pages/Welcome/Last")),
    exact: true,
  },
  {
    path: "referrals/:userId?",
    component: lazy(() => import("../pages/Referrals")),
    exact: true,
  },
  {
    path: "user/profile",
    component: lazy(() => import("../pages/Profile/Edit")),
    exact: true,
  },
  {
    path: "admin/users",
    component: lazy(() => import("../pages/Admin/Users")),
    exact: true,
    isAdmin: true,
  },
  {
    path: "admin/:id/photos",
    component: lazy(() => import("../pages/Admin/Photos")),
    exact: true,
    isAdmin: true,
  },
];

export default routes;
